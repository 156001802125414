<template>
  <div class="p-mileBanner">
    <div v-if="banners" class="c-section c-section--fluid">
      <div class="c-section__inner">
        <Banner
          v-for="item in banners"
          :key="item.index"
          :src="item['image_url']"
          :href="item['link_url'] ? item['link_url'] : false"
          alt="" />
      </div>
      <!-- /.c-section__inner -->
    </div>
    <!-- /.c-section -->
  </div>
  <!-- /.p-mileBanner -->
</template>

<script>
import { SystemDialogMessage } from '@/constants/enums';
export default {
  data: () => ({
    banners: undefined
  }),

  async created() {
    await this.fetchBanners();
  },

  methods: {
    /**
     * ユーザのオンラインストア情報を取得
     */
    async fetchBanners() {
      try {
        this.$_loading_start();

        const { data } = await this.$repositories('banners').getMilePage();
        this.banners = data;
      } catch {
        window.location.href = this.$systemDialog(
          SystemDialogMessage.ERROR_COMMON
        );
      } finally {
        this.$_loading_stop();
      }
    }
  }
};
</script>
