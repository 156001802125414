var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "p-mileBanner" }, [
    _vm.banners
      ? _c("div", { staticClass: "c-section c-section--fluid" }, [
          _c(
            "div",
            { staticClass: "c-section__inner" },
            _vm._l(_vm.banners, function (item) {
              return _c("Banner", {
                key: item.index,
                attrs: {
                  src: item["image_url"],
                  href: item["link_url"] ? item["link_url"] : false,
                  alt: "",
                },
              })
            }),
            1
          ),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }